// main.js

import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Importar estilos do BootstrapVue (opcional)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

// Instalar BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Configurar a diretiva draggable para modais
Vue.directive('draggable', {
  bind(el) {
    el.style.position = 'absolute';
    el.style.left = `${(window.innerWidth - el.clientWidth) / 2}px`; // Centraliza horizontalmente
    el.style.top = `${(window.innerHeight - el.clientHeight) / 2}px`; // Centraliza verticalmente

    const dialog = el.querySelector('.modal-dialog');
    let offsetX, offsetY;

    dialog.addEventListener('mousedown', startDrag);

    function startDrag(event) {
      event.preventDefault();
      offsetX = event.clientX - el.getBoundingClientRect().left;
      offsetY = event.clientY - el.getBoundingClientRect().top;

      document.addEventListener('mousemove', drag);
      document.addEventListener('mouseup', stopDrag);
    }

    function drag(event) {
      el.style.left = `${event.clientX - offsetX}px`;
      el.style.top = `${event.clientY - offsetY}px`;
    }

    function stopDrag() {
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('mouseup', stopDrag);
    }
  }
});

new Vue({
  render: h => h(App),
}).$mount('#app');
